
.contactInfo {
  height: 65vh;
  width: 94vw;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: center;
  align-content: space-around;
  background: transparent;
  color: #fff;
  justify-content: center;
  margin: -350px 2% 100px 2%;
} /* Contact Info Container */

  #contactImg {
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 1;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    gap: 20px;
    justify-content: center;
    justify-content: space-around;
    margin-top: 700px;
  } /* Contact Me Image */

#feedbackContainer {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 1;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  justify-content: space-around;
  margin-top: 30px;
} /* FeedBack Container */

  #feedbackForm {
    height: 400px;
    width: 60%;
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 1;
    align-items: center;
    background: #999;
    border: 1px solid #000;
    border-radius: 8px;
    gap: 15px;
    justify-content: center;
    justify-content: space-around;
    margin-right: 0 auto;
    min-width: 400px;
    padding: 15px 5px 5px 5px;
  } /* FeedBack Form */

  #feedbackForm input, textarea {
    height: 35px;
    width: 90%;
    border: 2px solid #000;
    border-radius: 8px;
    color: #000;
    padding: 2px 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
  } /* Input & TextArea Box */

  #userName {
    width: 90%;
    margin: 0px 5%;
  } /* User Name Box */

  #subject {
    width: 90%;
    margin: 0 5%;
  } /* Subject Input Box */

  #emailAddress {
    width: 90%;
    margin: 0 5%;
  } /* Email Address Input Box */

  #message {
    height: 150px;
    width: 90%;

  } /* Message Input Box */

  #sendMsg {
    width: 90%;
    background-color: rgb(54, 195, 241);
    margin: 0 5% 5px 5%;
  } /* Send Message Button */
