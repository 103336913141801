
.show {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: rgba(0,0,0,.87);
  justify-content: center;
  position: fixed;
  visibility: visible;
  z-index: 13;
} /* Show Lightbox */

.hide {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: rgba(0,0,0,.87);
  justify-content: center;
  position: fixed;
  visibility: hidden;
  z-index: 13;
} /* Hide Lightbox */