@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap);
@import url(https://use.fontawesome.com/releases/v5.8.1/css/all.css);
@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
} /* Global Setup */

html {
  font-size: 16px;
  scroll-behavior: smooth;
} /* Scroll Behavior */

body {
  margin: 0;
  min-height: 100vh;
}  /* Body Setup */

body::-webkit-scrollbar {
  width: 16px;
}  /* Scrollbar Width */

body::-webkit-scrollbar-track {
  background: rgba(117, 21, 80,.9);
}  /* Scrollbar Track Color */

body::-webkit-scrollbar-thumb {
  background: #f00;
  border-radius: 16px;
} /* Scrollbar Thumb */

image {
  padding: 0;
  margin: 0;
}  /* Image Setup */


.show {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: rgba(0,0,0,.87);
  justify-content: center;
  position: fixed;
  visibility: visible;
  z-index: 13;
} /* Show Lightbox */

.hide {
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background: rgba(0,0,0,.87);
  justify-content: center;
  position: fixed;
  visibility: hidden;
  z-index: 13;
} /* Hide Lightbox */
.navbar {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(-225deg, rgba(35, 6, 162,.8) 10%, rgba(69, 36, 107, .8) 35%, rgba(117, 21, 80, .8) 100%);
  font-size: 1.5rem;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 10;
} /* NavBar */

.navLogo {
  height: 40px;
  width: 100px;
  left: 50px;
  position: fixed;
  top: 10px;
} /* NavBar Logo*/

.navContainer {
  height: 80px;
  width: 100%;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
} /* Nav Container */

.navMenu {
  width: 100%;  
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  justify-content: center;
  text-align: center;
} /* Nav Menu Setup for 5 Selections*/

#navChoices {
  display: flex;
  align-items: center; 
  cursor: pointer;
  justify-self: center;
  margin-left: 20px;
  text-decoration: none;
} /* Nav Selections Start Position and Centering Text*/

.navItem {
  height: 50px;
} /* List Items are 80px which are the Selections */

.navLinks {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 900;
  padding: 0.5rem 1rem;
  text-decoration: none;
} /* Nav Link Text and Properties */

.navLinks:hover {
  border-bottom: 8px solid #f00;
} /* Nav Link Hover, Red Select line when pushes up 10px */

.fa-bars {
  color: #fff;
} /* Hamburg Bars from Fontawesome Lib*/

.fa-times { 
  color: #fff;
  font-size: 2rem;
} /* Hamburg X from Fontawesome Lib */

.menuIcon {
  display: none;
} /* Does not Display Hamburg Icon */

@media only screen and (max-width: 961px) {
  .navMenu { 
    height: 53.5vh;
    display: flex;
    flex-direction: row;
    border-radius: 0 10px 10px 0;
    left: -200px;
    opacity: 0;
    top: 84px;
    -webkit-transform: translate(-100%);
            transform: translate(-100%);
    z-index: 3;
  }
} /* } Transition to Mobile Menu */

@media only screen and (max-width: 960px) {
  .navMenu {
    height: 53.5vh;
    width: 200px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-225deg, rgba(35, 6, 162,1) 10%, rgba(69, 36, 107, 1) 35%, rgba(117, 21, 80, 1) 100%);
    border-radius: 0 10px 10px 0;
    opacity: 1;
    position: fixed;
    top: 84px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);   
    z-index: 3;
  } /* Side NavBar when the mobile menu X selected */

  .navMenuActive {
    height: 53.5vh;
    width: 250px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-225deg, rgba(35, 6, 162,1) 10%, rgba(69, 36, 107, 1) 35%, rgba(117, 21, 80, 1) 100%);
    border-radius: 0 10px 10px 0;
    left: -10px;
    opacity: 1;
    overflow: hidden;
    padding-bottom: 10px;
    padding-left: 25px;
    position: fixed;
    top: 84px;
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%); 
    transition: all 0.3s ease-out;     
    z-index: 3;
   } /* Side NavBar when the Mobile menu bars selected */

  .menuIcon {
      display: block;
      cursor: pointer;
      font-size: 1.8rem;
      position: absolute;
      right: 0;
      top: -8px;
      -webkit-transform: translate(-100%, 65%);
              transform: translate(-100%, 65%);
  } /* Positions my Hamburg and X on the NavBar */
} /* Media Query*/ 
 /* Intro Screen CSS */

  #dev {
    -webkit-text-fill-color: oldlace;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    height: 55vh;
    display: flex;
    flex-direction: column;
    color: oldlace;
    font-size: 5rem;   
    margin-top: -100px;
    overflow: hidden;
  } /* Web & Mobile Dev Block*/

  #site {
    -webkit-text-fill-color: oldlace;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    height: 55vh;
    display: flex;
    flex-direction: column;
    color: oldlace;
    font-size: 5rem;
    margin-top: 20px;
    overflow: hidden;
  } /* Website Awaits Block */

  .waiting {
    -webkit-text-fill-color: oldlace;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    display: flex;
    flex-direction: column;
    color: oldlace;
    font-weight: 900;
    font-size: 40px;
    margin-top: 8px;
  } /* Waiting for Text */

.building {
  -webkit-text-fill-color: oldlace;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  display: flex;
  flex-direction: column;
  color: oldlace;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 8px;
} /* Start Building Text*/

  .dreamBox {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  } /* Rolling Text Block */

  .dream {
    -webkit-text-fill-color: #00d9ff;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    display: flex;
    flex-direction: column;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    padding-top: 40px;
    position: absolute;
  } /* Rolling Text */

.btnGetStarted {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 20px;
  margin-top: 80px;
  margin-bottom: 45px;
  text-decoration: none;
  transition: all 0.3s ease-out;
} /* Get Started Button */

  .btnGetStarted:hover {
    background: #fff;
    color: #000;
    transition: all 0.3s ease-out;  
  } /* Get Started Button Hover */

.contactLink {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 2.3rem;
  font-weight: 700;
  padding: 5px 30px;
  text-decoration: none;
} /* Get Started Click to Contact Link*/

  .contactLink:Hover {
    -webkit-text-fill-color: black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  } /* Get Started Click to Contact Link Hover*/

@media screen and (max-width: 1200px) {
  #dev {
    font-size: 60px;
  }  
  #site {
    font-size: 60px;
    margin-bottom: 25px;
  }
  .waiting {
    font-size: 35px;
    margin-top: -10px;
  }
  .dream {
    font-size: 60px;
  }
  .building {
    font-size: 35px;
  }
  .rollingText {
    font-size: 60px;
  }
} /* Media Query Max Width 1200 */

@media screen and (max-width: 960px) {
  #dev {
    font-size: 50px;
  } 
  #site {
    font-size: 50px;
  }
  .waiting {
    font-size: 30px;
    margin-top: -6px;
  }
  .dream {
    font-size: 50px;
  }
  .building {
    font-size: 30px;
  }
  .rollingText {
    font-size: 50px;
  }
} /* Media Query Max Width 960 */

@media screen and (max-width: 630px) {
  #dev {
    font-size: 38px;
  }
  #site {
    font-size: 38px;
  }
  .waiting {
    font-size: 28px;
    margin-top: 1px;
  }
  .dream {
    font-size: 40px;
  }
  .building {
    font-size: 28px;
  }
  .rollingText {
    font-size: 40px;
  }
  .btnMobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
} /* Media Query Max Width 630 */


.card {
  height: 300px;
  width: 225px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  background-color: transparent;
  border-radius: 10%;
  margin: 525px 0px 0px 0px;
  -webkit-perspective: 600px;
          perspective: 600px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
} /* Card */

  .card .front {
    height: 100%;
    width: 100%;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    background-color: black;
    background-image: url(/static/media/Dave225x300.face2454.jpg);
    border: 1px solid #fff;
    border-radius: 10%;
    image-orientation: center;
    left: 0px;
    position: absolute;
    top: 0px;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    transition: 0.5s;
  } /* Card Front */

  .card:hover .front {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  } /* Card Front Hover to Rotate */

.card .back {
  height: 100%;
  width: 100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  background-color: black;
  border: 1px solid #fff;
  border-radius: 10%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  transition: 0.5s;
} /* Card Back */

  .card:hover .back {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  } /* Card Back Hover to Rotate */

  .details {
    width: 100%;
    box-sizing: border-box;
    left: 0;
    padding: 20px;
    position: absolute;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  } /* Back of the Card Details */

  .details #myname {
    color: white;
    font-family: 'Oleo Script', cursive;
    font-size: 28px;
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
  } /* Name on Card */

  .details p {
    color: white;
    font-size: 29px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  } /* Back p Tags */

  .details span {
    display: inline-block;
    color: #a7a7a7;
    font-size: 16px;
    margin-top: 20px;
  } /* Back Span */

  .social-icons {
    margin-top: 30px;
    padding-left: 20px;
  } /* Social Icons on Back of Card */

  .social-icons a {
    height: 36px;
    width: 36px;
    display: inline-block;
    background-color: #262626;
    border: none;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    outline: none;
    padding-top: 10px;
    text-decoration: none;
    transition: 0.5s;
  } /* Social Icon Anchor Tags */

  .social-icons a:hover {
    background-color: #e91e63;
  } /* Social Icon Hover */


.contactInfo {
  height: 65vh;
  width: 94vw;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  align-items: center;
  align-content: space-around;
  background: transparent;
  color: #fff;
  justify-content: center;
  margin: -350px 2% 100px 2%;
} /* Contact Info Container */

  #contactImg {
    width: 90%;
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 1;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    grid-gap: 20px;
    gap: 20px;
    justify-content: center;
    justify-content: space-around;
    margin-top: 700px;
  } /* Contact Me Image */

#feedbackContainer {
  width: 90%;
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 1;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  justify-content: space-around;
  margin-top: 30px;
} /* FeedBack Container */

  #feedbackForm {
    height: 400px;
    width: 60%;
    display: flex;
    flex-flow: row wrap;
    flex-shrink: 1;
    align-items: center;
    background: #999;
    border: 1px solid #000;
    border-radius: 8px;
    grid-gap: 15px;
    gap: 15px;
    justify-content: center;
    justify-content: space-around;
    margin-right: 0 auto;
    min-width: 400px;
    padding: 15px 5px 5px 5px;
  } /* FeedBack Form */

  #feedbackForm input, textarea {
    height: 35px;
    width: 90%;
    border: 2px solid #000;
    border-radius: 8px;
    color: #000;
    padding: 2px 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
  } /* Input & TextArea Box */

  #userName {
    width: 90%;
    margin: 0px 5%;
  } /* User Name Box */

  #subject {
    width: 90%;
    margin: 0 5%;
  } /* Subject Input Box */

  #emailAddress {
    width: 90%;
    margin: 0 5%;
  } /* Email Address Input Box */

  #message {
    height: 150px;
    width: 90%;

  } /* Message Input Box */

  #sendMsg {
    width: 90%;
    background-color: rgb(54, 195, 241);
    margin: 0 5% 5px 5%;
  } /* Send Message Button */


.footerContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-image: linear-gradient(-225deg, #2206a0 10%, #46246d 35%, #751550 100%);
  color: #fff;
  justify-content: center;
  margin-top: 270px;
  overflow: hidden;
  padding: 4rem 0 2rem 0;
} /* Footer Container */

#footerLeft{
  width: 50%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  margin-top: -25px;
} /* Footer Left Side */

.footerSocialIcons a {
  display: column;
  flex-shrink: 1;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  margin: 10px 10px;
  text-decoration: none;
} /* Social Icon Anchor Tags */

.footerSocialIcons a:hover {
  background-color: #e91e63;
} /* Social Icon Hover */

.footerRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  justify-content: center;
  margin-top: -50px;
} /* Footer Right Side */

.footerInfo {
  display: inline-block;
  border-radius: 10px;
  color: #fff;
  margin: 2% auto;
} /* Footer Right Side Block of Text */

.footerInfo p {
  font-size: 1.25rem;
  margin-bottom: 54px;
  margin-top: -57px;
  padding: 3px 0px;
} /* Footer Info P Tags */

.footer {
  margin-top: -128px;
  z-index: 1;
} /* Move footer to start in Contact Screen */

