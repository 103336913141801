@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oleo+Script:wght@400;700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  padding: 0;
  margin: 0;
} /* Global Setup */

html {
  font-size: 16px;
  scroll-behavior: smooth;
} /* Scroll Behavior */

body {
  margin: 0;
  min-height: 100vh;
}  /* Body Setup */

body::-webkit-scrollbar {
  width: 16px;
}  /* Scrollbar Width */

body::-webkit-scrollbar-track {
  background: rgba(117, 21, 80,.9);
}  /* Scrollbar Track Color */

body::-webkit-scrollbar-thumb {
  background: #f00;
  border-radius: 16px;
} /* Scrollbar Thumb */

image {
  padding: 0;
  margin: 0;
}  /* Image Setup */
