
.card {
  height: 300px;
  width: 225px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: transparent;
  border-radius: 10%;
  margin: 525px 0px 0px 0px;
  perspective: 600px;
  transform: translate(-50%, -50%);
  transform-style: preserve-3d;
} /* Card */

  .card .front {
    height: 100%;
    width: 100%;
    backface-visibility: hidden;
    background-color: black;
    background-image: url("../images/Dave225x300.jpg");
    border: 1px solid #fff;
    border-radius: 10%;
    image-orientation: center;
    left: 0px;
    position: absolute;
    top: 0px;
    transform: rotateX(0deg);
    transition: 0.5s;
  } /* Card Front */

  .card:hover .front {
    transform: rotateX(-180deg);
  } /* Card Front Hover to Rotate */

.card .back {
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  background-color: black;
  border: 1px solid #fff;
  border-radius: 10%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotateX(180deg);
  transition: 0.5s;
} /* Card Back */

  .card:hover .back {
    transform: rotateX(0deg);
  } /* Card Back Hover to Rotate */

  .details {
    width: 100%;
    box-sizing: border-box;
    left: 0;
    padding: 20px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  } /* Back of the Card Details */

  .details #myname {
    color: white;
    font-family: 'Oleo Script', cursive;
    font-size: 28px;
    margin: 0;
    margin-bottom: 15px;
    padding: 0;
  } /* Name on Card */

  .details p {
    color: white;
    font-size: 29px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  } /* Back p Tags */

  .details span {
    display: inline-block;
    color: #a7a7a7;
    font-size: 16px;
    margin-top: 20px;
  } /* Back Span */

  .social-icons {
    margin-top: 30px;
    padding-left: 20px;
  } /* Social Icons on Back of Card */

  .social-icons a {
    height: 36px;
    width: 36px;
    display: inline-block;
    background-color: #262626;
    border: none;
    border-radius: 50%;
    color: #fff;
    margin-right: 10px;
    outline: none;
    padding-top: 10px;
    text-decoration: none;
    transition: 0.5s;
  } /* Social Icon Anchor Tags */

  .social-icons a:hover {
    background-color: #e91e63;
  } /* Social Icon Hover */
