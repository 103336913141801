
.footerContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background-image: linear-gradient(-225deg, #2206a0 10%, #46246d 35%, #751550 100%);
  color: #fff;
  justify-content: center;
  margin-top: 270px;
  overflow: hidden;
  padding: 4rem 0 2rem 0;
} /* Footer Container */

#footerLeft{
  width: 50%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
  margin-top: -25px;
} /* Footer Left Side */

.footerSocialIcons a {
  display: column;
  flex-shrink: 1;
  align-items: center;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  margin: 10px 10px;
  text-decoration: none;
} /* Social Icon Anchor Tags */

.footerSocialIcons a:hover {
  background-color: #e91e63;
} /* Social Icon Hover */

.footerRight {
  width: 50%;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  justify-content: center;
  margin-top: -50px;
} /* Footer Right Side */

.footerInfo {
  display: inline-block;
  border-radius: 10px;
  color: #fff;
  margin: 2% auto;
} /* Footer Right Side Block of Text */

.footerInfo p {
  font-size: 1.25rem;
  margin-bottom: 54px;
  margin-top: -57px;
  padding: 3px 0px;
} /* Footer Info P Tags */

.footer {
  margin-top: -128px;
  z-index: 1;
} /* Move footer to start in Contact Screen */
