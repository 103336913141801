.navbar {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background-image: linear-gradient(-225deg, rgba(35, 6, 162,.8) 10%, rgba(69, 36, 107, .8) 35%, rgba(117, 21, 80, .8) 100%);
  font-size: 1.5rem;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 10;
} /* NavBar */

.navLogo {
  height: 40px;
  width: 100px;
  left: 50px;
  position: fixed;
  top: 10px;
} /* NavBar Logo*/

.navContainer {
  height: 80px;
  width: 100%;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
} /* Nav Container */

.navMenu {
  width: 100%;  
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
  justify-content: center;
  text-align: center;
} /* Nav Menu Setup for 5 Selections*/

#navChoices {
  display: flex;
  align-items: center; 
  cursor: pointer;
  justify-self: center;
  margin-left: 20px;
  text-decoration: none;
} /* Nav Selections Start Position and Centering Text*/

.navItem {
  height: 50px;
} /* List Items are 80px which are the Selections */

.navLinks {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 1.7rem;
  font-weight: 900;
  padding: 0.5rem 1rem;
  text-decoration: none;
} /* Nav Link Text and Properties */

.navLinks:hover {
  border-bottom: 8px solid #f00;
} /* Nav Link Hover, Red Select line when pushes up 10px */

.fa-bars {
  color: #fff;
} /* Hamburg Bars from Fontawesome Lib*/

.fa-times { 
  color: #fff;
  font-size: 2rem;
} /* Hamburg X from Fontawesome Lib */

.menuIcon {
  display: none;
} /* Does not Display Hamburg Icon */

@media only screen and (max-width: 961px) {
  .navMenu { 
    height: 53.5vh;
    display: flex;
    flex-direction: row;
    border-radius: 0 10px 10px 0;
    left: -200px;
    opacity: 0;
    top: 84px;
    transform: translate(-100%);
    z-index: 3;
  }
} /* } Transition to Mobile Menu */

@media only screen and (max-width: 960px) {
  .navMenu {
    height: 53.5vh;
    width: 200px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-225deg, rgba(35, 6, 162,1) 10%, rgba(69, 36, 107, 1) 35%, rgba(117, 21, 80, 1) 100%);
    border-radius: 0 10px 10px 0;
    opacity: 1;
    position: fixed;
    top: 84px;
    transform: translateX(-100%);   
    z-index: 3;
  } /* Side NavBar when the mobile menu X selected */

  .navMenuActive {
    height: 53.5vh;
    width: 250px;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-225deg, rgba(35, 6, 162,1) 10%, rgba(69, 36, 107, 1) 35%, rgba(117, 21, 80, 1) 100%);
    border-radius: 0 10px 10px 0;
    left: -10px;
    opacity: 1;
    overflow: hidden;
    padding-bottom: 10px;
    padding-left: 25px;
    position: fixed;
    top: 84px;
    transform: translateX(-20%); 
    transition: all 0.3s ease-out;     
    z-index: 3;
   } /* Side NavBar when the Mobile menu bars selected */

  .menuIcon {
      display: block;
      cursor: pointer;
      font-size: 1.8rem;
      position: absolute;
      right: 0;
      top: -8px;
      transform: translate(-100%, 65%);
  } /* Positions my Hamburg and X on the NavBar */
} /* Media Query*/ 