 /* Intro Screen CSS */

  #dev {
    -webkit-text-fill-color: oldlace;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    height: 55vh;
    display: flex;
    flex-direction: column;
    color: oldlace;
    font-size: 5rem;   
    margin-top: -100px;
    overflow: hidden;
  } /* Web & Mobile Dev Block*/

  #site {
    -webkit-text-fill-color: oldlace;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    height: 55vh;
    display: flex;
    flex-direction: column;
    color: oldlace;
    font-size: 5rem;
    margin-top: 20px;
    overflow: hidden;
  } /* Website Awaits Block */

  .waiting {
    -webkit-text-fill-color: oldlace;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    display: flex;
    flex-direction: column;
    color: oldlace;
    font-weight: 900;
    font-size: 40px;
    margin-top: 8px;
  } /* Waiting for Text */

.building {
  -webkit-text-fill-color: oldlace;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
  display: flex;
  flex-direction: column;
  color: oldlace;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 20px;
  margin-top: 8px;
} /* Start Building Text*/

  .dreamBox {
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  } /* Rolling Text Block */

  .dream {
    -webkit-text-fill-color: #00d9ff;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: black;
    display: flex;
    flex-direction: column;
    font-size: 5rem;
    font-style: normal;
    font-weight: 700;
    padding-top: 40px;
    position: absolute;
  } /* Rolling Text */

.btnGetStarted {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 10px;
  font-size: 20px;
  margin-top: 80px;
  margin-bottom: 45px;
  text-decoration: none;
  transition: all 0.3s ease-out;
} /* Get Started Button */

  .btnGetStarted:hover {
    background: #fff;
    color: #000;
    transition: all 0.3s ease-out;  
  } /* Get Started Button Hover */

.contactLink {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 2.3rem;
  font-weight: 700;
  padding: 5px 30px;
  text-decoration: none;
} /* Get Started Click to Contact Link*/

  .contactLink:Hover {
    -webkit-text-fill-color: black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
  } /* Get Started Click to Contact Link Hover*/

@media screen and (max-width: 1200px) {
  #dev {
    font-size: 60px;
  }  
  #site {
    font-size: 60px;
    margin-bottom: 25px;
  }
  .waiting {
    font-size: 35px;
    margin-top: -10px;
  }
  .dream {
    font-size: 60px;
  }
  .building {
    font-size: 35px;
  }
  .rollingText {
    font-size: 60px;
  }
} /* Media Query Max Width 1200 */

@media screen and (max-width: 960px) {
  #dev {
    font-size: 50px;
  } 
  #site {
    font-size: 50px;
  }
  .waiting {
    font-size: 30px;
    margin-top: -6px;
  }
  .dream {
    font-size: 50px;
  }
  .building {
    font-size: 30px;
  }
  .rollingText {
    font-size: 50px;
  }
} /* Media Query Max Width 960 */

@media screen and (max-width: 630px) {
  #dev {
    font-size: 38px;
  }
  #site {
    font-size: 38px;
  }
  .waiting {
    font-size: 28px;
    margin-top: 1px;
  }
  .dream {
    font-size: 40px;
  }
  .building {
    font-size: 28px;
  }
  .rollingText {
    font-size: 40px;
  }
  .btnMobile {
    display: block;
    text-decoration: none;
  }
  .btn {
    width: 100%;
  }
} /* Media Query Max Width 630 */
